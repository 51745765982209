(function () {
  async function fetchKPIData() {
    try {
      const res = await fetch("/RepositorySite/retrieveKPIs");
      const json = await res.json();
      setKPIs(json);
    } catch (e) {
      console.error("Error fetching KPI data: ", e);
    }
  }

  function setKPIs(data) {
    const specimens = document.getElementById("pki-specimens");
    const participants = document.getElementById("pki-participants");
    const types = document.getElementById("pki-types");
    const studies = document.getElementById("pki-studies");

    specimens.innerText = data.specimens;
    participants.innerText = data.participants;
    types.innerText = data.types;
    studies.innerText = data.studies;
  }

  document.addEventListener("DOMContentLoaded", function () {
    // fade in hero banner
    var hero = document.querySelector(".fade-in");
    hero.style.opacity = "1";

    fetchKPIData();

    // fade in cards when visible using InterSectionObserver, if available
    var cardDeck = document.getElementById("card-deck");

    if ("IntersectionObserver" in window) {
      function onInView(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.remove("lg:opacity-0");
            const cards = entry.target.querySelectorAll(".card");
            cards.forEach(card =>
              card.classList.remove(
                "lg:translate-x-16",
                "lg:translate-y-16",
                "lg:-translate-x-16"
              )
            );
          }
        });
      }

      var observer = new IntersectionObserver(onInView, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
      });

      observer.observe(cardDeck);
    } else {
      cardDeck.classList.remove("lg:opacity-0");
      const cards = cardDeck.querySelectorAll(".card");
      cards.forEach(card =>
        card.classList.remove(
          "lg:translate-x-16",
          "lg:translate-y-16",
          "lg:-translate-x-16"
        )
      );
    }
  });
})();
